// audioModule.js - Add this to your Vuex store

export const audioModule = {
    state: {
        // audioController: new Audio(),
        // globalVolume: 0.8,
        currentTrack: null,
        preloadedAudio: {}, // Track preloaded audio files
        trackHistory: [], // Keep track of recently played tracks
        playbackStatus: 'stopped', // 'playing', 'paused', 'loading', 'stopped', 'error'
        lastError: null
    },

    getters: {
        // audio_controller: state => state.audioController,
        // global_volume: state => state.globalVolume,
        current_track: state => state.currentTrack,
        playback_status: state => state.playbackStatus,

        // Check if playback is even possible
        can_play_audio: () => {
            // Check for browser audio support
            const audio = new Audio();
            return audio && typeof audio.play === 'function';
        }
    },

    mutations: {
        setAudioController(state, audioElement) {
            state.audioController = audioElement;
        },

        // setGlobalVolume(state, volume) {
        //     state.globalVolume = volume;
        //     if (state.audioController) {
        //         state.audioController.volume = volume;
        //     }
        // },

        setCurrentTrack(state, track) {
            state.currentTrack = track;

            // Add to history
            if (track) {
                // Remove if already in history to avoid duplicates
                state.trackHistory = state.trackHistory.filter(t => t.id !== track.id);
                // Add to beginning
                state.trackHistory.unshift(track);
                // Limit history size
                if (state.trackHistory.length > 20) {
                    state.trackHistory.pop();
                }
            }
        },

        setPlaybackStatus(state, status) {
            state.playbackStatus = status;
        },

        setLastError(state, error) {
            state.lastError = error;
        },

        addPreloadedAudio(state, { url, audio }) {
            state.preloadedAudio[url] = audio;
        },

        clearPreloadedAudio(state) {
            // Release memory for preloaded audio
            Object.values(state.preloadedAudio).forEach(audio => {
                try {
                    // Remove source and release resources
                    audio.src = '';
                    audio.load();
                } catch (e) {
                    // Ignore errors during cleanup
                }
            });
            state.preloadedAudio = {};
        }
    },

    actions: {
        playTrack({ commit, state, dispatch }, track) {
            if (!track) return;

            commit('setCurrentTrack', track);
            commit('setPlaybackStatus', 'loading');

            // Get appropriate audio URL
            const audioUrl = track.is_premium_theme && !state.userCanAccessTheme ?
                track.watermark_music :
                track.clear_music;

            // Check if we have preloaded this audio
            if (state.preloadedAudio[audioUrl]) {
                console.log("Using preloaded audio for", track.title);
                // Use the preloaded audio's source
                state.audioController.src = audioUrl;
            } else {
                // Load new source
                state.audioController.src = audioUrl;
                state.audioController.load();
            }

            // Try to play
            state.audioController.play()
                .then(() => {
                    commit('setPlaybackStatus', 'playing');
                    // Preload next track if possible
                    dispatch('preloadNextTrack');
                })
                .catch(err => {
                    console.error("Error playing track:", err);
                    commit('setPlaybackStatus', 'error');
                    commit('setLastError', err.message || "Failed to play audio");
                });
        },

        pauseTrack({ commit, state }) {
            if (state.audioController) {
                state.audioController.pause();
                commit('setPlaybackStatus', 'paused');
            }
        },

        playNextTrack({ state, dispatch, rootGetters }) {
            // Find index of current track in filtered tracks
            const tracks = rootGetters['browse/filteredTracks'] || [];
            if (!tracks.length || !state.currentTrack) return;

            const currentIndex = tracks.findIndex(t => t.id === state.currentTrack.id);
            if (currentIndex === -1 || currentIndex >= tracks.length - 1) return;

            // Play the next track
            const nextTrack = tracks[currentIndex + 1];
            dispatch('playTrack', nextTrack);

            // Scroll to track for visibility
            setTimeout(() => {
                const trackElement = document.querySelector(`.track-${nextTrack.id}`);
                if (trackElement) {
                    trackElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
                }
            }, 100);
        },

        playPreviousTrack({ state, dispatch, rootGetters }) {
            // Find index of current track in filtered tracks
            const tracks = rootGetters['browse/filteredTracks'] || [];
            if (!tracks.length || !state.currentTrack) return;

            const currentIndex = tracks.findIndex(t => t.id === state.currentTrack.id);
            if (currentIndex <= 0) return;

            // Play the previous track
            const prevTrack = tracks[currentIndex - 1];
            dispatch('playTrack', prevTrack);

            // Scroll to track for visibility
            setTimeout(() => {
                const trackElement = document.querySelector(`.track-${prevTrack.id}`);
                if (trackElement) {
                    trackElement.scrollIntoView({ block: "nearest", behavior: "smooth" });
                }
            }, 100);
        },

        handleTrackEnded({ dispatch, state }) {
            console.log("Track ended, playing next");
            dispatch('playNextTrack');
        },

        preloadNextTrack({ state, rootGetters }) {
            // Find tracks that might be played next and preload them
            const tracks = rootGetters['browse/filteredTracks'] || [];
            if (!tracks.length || !state.currentTrack) return;

            const currentIndex = tracks.findIndex(t => t.id === state.currentTrack.id);
            if (currentIndex === -1 || currentIndex >= tracks.length - 1) return;

            // Get next track URL
            const nextTrack = tracks[currentIndex + 1];
            const nextUrl = nextTrack.is_premium_theme && !state.userCanAccessTheme ?
                nextTrack.watermark_music :
                nextTrack.clear_music;

            // Don't preload if already in cache
            if (state.preloadedAudio[nextUrl]) return;

            // Preload
            const tempAudio = new Audio();
            tempAudio.preload = 'auto';
            tempAudio.src = nextUrl;
            tempAudio.load();

            // Store for later use
            state.preloadedAudio[nextUrl] = tempAudio;

            console.log("Preloaded next track:", nextTrack.title);
        },

        preloadAudioFile({ state, commit }, url) {
            if (!url || state.preloadedAudio[url]) return;

            // Create a temporary audio element to preload
            const tempAudio = new Audio();
            tempAudio.preload = 'auto';
            tempAudio.src = url;
            tempAudio.load();

            // Store it in the state
            commit('addPreloadedAudio', { url, tempAudio });
        },

        resetAudioController({ commit, state }) {
            // Pause current audio if playing
            if (state.audioController) {
                try {
                    state.audioController.pause();
                } catch (e) {
                    // Ignore errors during pause
                }
            }

            // Create a new audio element
            const newAudio = new Audio();
            newAudio.preload = 'auto';
            newAudio.volume = state.globalVolume;

            // Update in store
            commit('setAudioController', newAudio);
            commit('setPlaybackStatus', 'stopped');
        },

        // Clean up audio resources when no longer needed (e.g., on route change)
        cleanupAudioResources({ commit }) {
            commit('clearPreloadedAudio');
        }
    }
};